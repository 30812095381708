import * as React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Footer from "../components/footer";
import NavBar from "../components/navigation";
import GoTop from "../components/goTop";
import WhatsappButton from "../components/whatsapp";

const NotFoundPage = ({ data }) => {
  const {
    gcms: { links },
  } = data;

  const { navigation } = links[0];

  return (
    <>
      <NavBar navigation={navigation} />
      <div className="bg-white">
        <div className="text-center w-full mx-auto  py-12 px-4 sm:px-6 lg:py-32 lg:px-8 z-20 ">
          <p className="mb-8">
            <span className="block">Seems like you are lost in Space</span>
          </p>

          <div className=" mx-auto md:w-96">
            <StaticImage src={"../images/404.svg"} alt="404" />
          </div>

          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-12 inline-flex rounded-md shadow">
              <button
                type="button"
                className="py-4 px-6  bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                <Link to="/" className="font-cursive">
                  <p className="text-white"> Go home </p>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <WhatsappButton />
      <GoTop />
      <Footer navigation={navigation} />
    </>
  );
};

export const query = graphql`
  query notfoundQuery {
    gcms {
      links(locales: en) {
        navigation {
          name
          slug
          id
        }
      }
    }
  }
`;

export default NotFoundPage;
